import { DecisionBranch, DecisionCriteriaOperator } from '../models/decision-criteria';
import { type SelectOption } from '@core/components/select/select.component';

export const DESICION_BRANCHES: SelectOption[] = [
  {
    value: DecisionBranch.A,
    label: 'decisionModal.strings.branchA',
  },
  {
    value: DecisionBranch.B,
    label: 'decisionModal.strings.branchB',
  },
];

export const MULTIPLE_CRITERIA_OPERATORS: SelectOption[] = [
  {
    value: DecisionCriteriaOperator.All,
    label: 'decisionModal.strings.all',
  },
  {
    value: DecisionCriteriaOperator.Any,
    label: 'decisionModal.strings.any',
  },
];
