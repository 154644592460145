import { Component, Input, type OnInit, ViewEncapsulation, inject } from '@angular/core';
import {
  type AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalService } from '@core/services/modal.service';
import { type EsignTemplate } from '../../models/esign-template';
import { TemplateSelectionModalComponent } from '../../modals/template-selection-modal/template-selection-modal.component';
import { EnumService } from '@core/services/enum.service';
import { type SelectOption } from '@core/components/select/select.component';
import { TranslateModule } from '@ngx-translate/core';
import { SelectComponent } from '../../../core/components/select/select.component';
import { RichTextEditorComponent } from '../../../core/components/rich-text-editor/rich-text-editor.component';
import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { NgClass } from '@angular/common';
import { ScrollableAreaComponent } from '../../../core/components/scrollable-area/scrollable-area.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

@Component({
  selector: 'app-docusign-modal',
  templateUrl: './docusign-modal.component.html',
  styleUrls: ['./docusign-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    ScrollableAreaComponent,
    NgClass,
    FormErrorMessagesComponent,
    RichTextEditorComponent,
    SelectComponent,
    TranslateModule,
  ],
})
export class DocusignModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly modalService = inject(ModalService);
  private readonly enumService = inject(EnumService);
  @Input() data: any;
  @Input() workflowType: string;
  public form: UntypedFormGroup;
  public fileCategories: SelectOption[];
  public templatesControl: AbstractControl;
  public maxScrollAreaHeight: number;

  public templateErrors = {
    required: 'docusignModal.errors.templatesRequired',
    fileCategoriesRequired: 'docusignModal.errors.fileCategoriesRequired',
  };

  public get selectedTemplates(): EsignTemplate[] {
    return this.templatesControl.value;
  }

  public ngOnInit(): void {
    const data = this.data ? this.data : {};
    if (Object.keys(data).length > 0) {
      const regex = /\[\[\s*(.*?)\s*\]\]/g;
      data.text.html = data.text.html.replace(regex, '<span class="workflow-token">$1</span>');
    }
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(data.name, [Validators.required]),
      description: new UntypedFormControl(data.description, [Validators.required]),
      templates: new UntypedFormControl(data.templates || [], [Validators.required]),
      text: new UntypedFormControl(data.text, [Validators.required]),
    });

    this.templatesControl = this.form.get('templates');
    this.templatesControl.valueChanges.subscribe(() => {
      this.checkCategoriesSelection();
    });

    this.enumService
      .getFileCategories()
      .then((res) => (this.fileCategories = res))
      .catch(() => {});
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.maxScrollAreaHeight = this.getMaxScrollAreaHeight();
    }, 0);
  }

  public getMaxScrollAreaHeight(): number {
    const headerHeight = document.querySelector('.workflow-step-modal_header').clientHeight;
    const modalHeaderHeight = document.querySelector('.modal-header').clientHeight;
    return document.body.clientHeight - headerHeight - modalHeaderHeight - 64;
  }

  checkCategoriesSelection(): void {
    if (this.selectedTemplates.length === 0) {
      return;
    }

    const areCategoriesSelected = !this.selectedTemplates.find((temp) => !temp.fileCategory);

    if (!areCategoriesSelected) {
      this.templatesControl.setErrors({ fileCategoriesRequired: true });
    } else {
      this.templatesControl.setErrors(null);
    }
  }

  public save(): void {
    const areCategoriesSelected = !this.selectedTemplates.find((temp) => !temp.fileCategory);

    if (this.form.invalid || !areCategoriesSelected) {
      setTimeout(() => {
        const firstError = document.querySelector('.workflow-step-modal_scroll-area .has-error');
        firstError.scrollIntoView();
      }, 0);

      return;
    }

    this.activeModal.close(this.form.value);
  }

  public selectTemplates(): void {
    this.modalService
      .open({
        content: TemplateSelectionModalComponent,
        options: {
          size: 'xl',
        },
      })
      .result.then((templates: EsignTemplate[]) => {
        this.templatesControl.setValue(templates);
      })
      .catch(() => {});
  }

  public removeTemplate(template: EsignTemplate): void {
    const templates = this.selectedTemplates.filter((temp) => temp.id !== template.id);
    this.templatesControl.setValue(templates);
  }
}
