import { Component, ViewEncapsulation, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { type EsignTemplate } from '../../models/esign-template';
import { TranslateModule } from '@ngx-translate/core';
import { TableComponent } from '../../../core/components/table/table.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

@Component({
  selector: 'template-selection-modal',
  templateUrl: './template-selection-modal.component.html',
  styleUrls: ['./template-selection-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [FocusableDirective, AssetSrcDirective, TableComponent, TranslateModule],
})
export class TemplateSelectionModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  public tableConfig = {
    loadUrl: `api/esign/templates`,
    manualSearchBy: 'name',
    filters: [],
    buttons: [
      {
        label: 'docusignModal.controls.selectTemplates.buttonText',
        onClick: this.selectTemplates.bind(this),
        class: 'btn btn-primary submit-btn',
      },
    ],
    columns: [
      {
        name: 'name',
        label: 'common.labels.name',
        sortable: true,
        minWidth: '200px',
        maxWidth: '20fr',
        manualSortValueFunc: (template: EsignTemplate) => {
          return template.name;
        },
      },
    ],
  };

  private selectedFiles: EsignTemplate[] = [];
  public templates: EsignTemplate[] = [];

  public selectTemplates(): void {
    this.activeModal.close(this.selectedFiles);
  }

  public isFileSelected(file: EsignTemplate): boolean {
    return this.selectedFiles.find((selectedFile) => file.id === selectedFile.id) !== undefined;
  }

  public handleFileClick(file: EsignTemplate): void {
    this.selectedFiles = [file];
    /*     if (!this.isFileSelected(file)) {
      this.selectedFiles.push(file);
    } else {
      this.selectedFiles = this.selectedFiles.filter(f => f.id !== file.id);
    } */
  }
}
