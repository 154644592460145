import { Component, type OnInit, Input, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { type SelectOption } from '@core/components/select/select.component';
import { EnumService } from '@core/services/enum.service';
import { TranslateModule } from '@ngx-translate/core';
import { RichTextEditorComponent } from '../../../core/components/rich-text-editor/rich-text-editor.component';
import { NgxMaskDirective } from 'ngx-mask';
import { SelectComponent } from '../../../core/components/select/select.component';
import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { NgClass } from '@angular/common';
import { ScrollableAreaComponent } from '../../../core/components/scrollable-area/scrollable-area.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

@Component({
  selector: 'app-upload-file-modal',
  templateUrl: './upload-file-modal.component.html',
  styleUrls: ['./upload-file-modal.component.scss'],
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    ScrollableAreaComponent,
    NgClass,
    FormErrorMessagesComponent,
    SelectComponent,
    NgxMaskDirective,
    RichTextEditorComponent,
    TranslateModule,
  ],
})
export class UploadFileModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly enumService = inject(EnumService);
  @Input() data: any;
  @Input() workflowType: string;

  public form: UntypedFormGroup;
  public fileCategories: SelectOption[] = [];
  public fileTypes: SelectOption[] = [];
  public maxScrollAreaHeight: number;

  public ngOnInit(): void {
    const data = this.data ? this.data : {};
    if (Object.keys(data).length > 0) {
      const regex = /\[\[\s*(.*?)\s*\]\]/g;
      data.text.html = data.text.html.replace(regex, '<span class="workflow-token">$1</span>');
    }
    const fileTypes = data?.allowedUploadedFileCategories || [];
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(data.name, [Validators.required]),
      description: new UntypedFormControl(data.description, [Validators.required]),
      allowedUploadedFileCategories: new UntypedFormControl(fileTypes, [Validators.required]),
      standardFileName: new UntypedFormControl(data.standardFileName, []),
      fileCategory: new UntypedFormControl(data.fileCategory, [Validators.required]),
      fileSize: new UntypedFormControl(data.fileSize, [Validators.required, Validators.max(50)]),
      text: new UntypedFormControl(data.text, [Validators.required]),
    });

    this.enumService
      .getFileCategories()
      .then((res) => (this.fileCategories = res))
      .catch(() => {});

    this.enumService
      .getFileTypes()
      .then((res) => {
        this.fileTypes = res.map((type) => {
          return {
            value: type.key,
            label: type.title,
          };
        });
      })
      .catch(() => {});
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.maxScrollAreaHeight = this.getMaxScrollAreaHeight();
    }, 0);
  }

  public getMaxScrollAreaHeight(): number {
    const headerHeight = document.querySelector('.workflow-step-modal_header').clientHeight;
    const modalHeaderHeight = document.querySelector('.modal-header').clientHeight;
    return document.body.clientHeight - headerHeight - modalHeaderHeight - 64;
  }

  public save(): void {
    if (this.form.invalid) {
      setTimeout(() => {
        const firstError = document.querySelector('.workflow-step-modal_scroll-area .has-error');
        firstError.scrollIntoView();
      }, 0);

      return;
    }
    const value = this.form.value;
    this.activeModal.close(value);
  }
}
