import { Component, type OnInit, Input, ViewChild, inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { type CustomFormElement } from '@clover/custom-forms/models/form-element';
import { ToastrService } from '@core/services/toastr.service';
import { FormBuilderComponent } from '@clover/custom-forms/form-builder/form-builder.component';
import { WorkflowType } from '../../models/workflow';
import { type LogicFormField } from '@clover/custom-forms/models/logic-form-field';
import { WorkflowsService } from '../../workflows.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { FormBuilderComponent as FormBuilderComponent_1 } from '../../../custom-forms/form-builder/form-builder.component';
import { NgStyle, NgClass } from '@angular/common';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { WorkflowBusinessObject } from '@clover/custom-forms/models/workflow-business-object';

@Component({
  selector: 'app-custom-form-modal',
  templateUrl: './custom-form-modal.component.html',
  styleUrls: ['./custom-form-modal.component.scss'],
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    NgStyle,
    FormBuilderComponent_1,
    NgClass,
    FormErrorMessagesComponent,
    TranslateModule,
  ],
})
export class CustomFormModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly toastr = inject(ToastrService);
  private readonly workflowService = inject(WorkflowsService);
  @Input() data: any;
  @Input() workflowType: WorkflowType;
  @Input() workflowStepId: number;
  @ViewChild('formBuilder') formBuilder: FormBuilderComponent;
  public form: UntypedFormGroup;
  public maxScrollAreaHeight: string;

  public tabs = {
    build: 0,
    settings: 1,
  };

  public selectedTab = 0;
  public formData: CustomFormElement[] = [];

  public logicFields: LogicFormField[] = [];
  public businessObjects: WorkflowBusinessObject[] = [];

  public ngOnInit(): void {
    const data = this.data ? this.data : {};
    this.logicFields = [...this.workflowService.logicFields];
    this.businessObjects = [...this.workflowService.businessObjects];

    this.formData = data.fields ? data.fields : [];
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(data.name, [Validators.required]),
      description: new UntypedFormControl(data.description, [Validators.required]),
    });

    setTimeout(() => {
      this.maxScrollAreaHeight = this.getMaxScrollAreaHeight();
    }, 0);
  }

  public getMaxScrollAreaHeight(): string {
    const headerHeight = document.querySelector('.workflow-step-modal_header').clientHeight;
    const modalHeaderHeight = document.querySelector('.modal-header').clientHeight;
    const tabsHeight = document.querySelector('.custom-form-modal_tabs').clientHeight;
    return `${document.body.clientHeight * 0.9 - headerHeight - modalHeaderHeight - tabsHeight}px`;
  }

  public save(): void {
    this.formBuilder.saveEdit();
    this.workflowService.logicFields = [...this.logicFields];
    this.workflowService.businessObjects = [...this.businessObjects];
    setTimeout(() => {
      if (this.form.invalid) {
        this.selectedTab = this.tabs.settings;
        return;
      }

      if (this.formData.length === 0) {
        this.toastr.error('customFormModal.errors.emptyForm');
        return;
      }

      const formErrors = this.formBuilder.getFormValidationErrors();
      if (formErrors.hasInactiveFields) {
        this.toastr.error('customFormModal.errors.formHasInactiveFields');
        return;
      }

      if (formErrors.hasMissingProps) {
        this.toastr.error('customFormModal.errors.missingProps');
        return;
      }

      this.activeModal.close({
        ...this.form.value,
        fields: this.formData,
      });
    }, 0);
  }
}
