import { Component, Input, type OnInit, ViewEncapsulation, inject } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { type SelectOption } from '@core/components/select/select.component';
import { TranslateModule } from '@ngx-translate/core';
import { RecipientSelectorComponent } from '../../components/recipient-selector/recipient-selector.component';
import { MultipleStringInputComponent } from '../../../core/components/multiple-string-input/multiple-string-input.component';
import { SelectComponent } from '../../../core/components/select/select.component';
import { ToggleComponent } from '../../../../stories/forms/toggle/toggle.component';
import { RichTextEditorComponent } from '../../../core/components/rich-text-editor/rich-text-editor.component';
import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { NgClass } from '@angular/common';
import { ScrollableAreaComponent } from '../../../core/components/scrollable-area/scrollable-area.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

@Component({
  selector: 'app-reassign-task-modal',
  templateUrl: './reassign-task-modal.component.html',
  styleUrls: ['./reassign-task-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    ScrollableAreaComponent,
    NgClass,
    FormErrorMessagesComponent,
    RichTextEditorComponent,
    ToggleComponent,
    SelectComponent,
    MultipleStringInputComponent,
    RecipientSelectorComponent,
    TranslateModule,
  ],
})
export class ReassignTaskModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  @Input() data: any;
  @Input() workflowType: string;
  maxScrollAreaHeight: number;

  public form: UntypedFormGroup;
  public assignmentTypes: SelectOption[] = [
    {
      value: 'Internal',
      label: 'workflows.controls.assignmentType.options.internal',
    },
    {
      value: 'External',
      label: 'workflows.controls.assignmentType.options.external',
    },
  ];

  public ngOnInit(): void {
    const data = this.data
      ? this.data
      : { };
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(data.name, [Validators.required]),
      description: new UntypedFormControl(data.description, [Validators.required]),
      assignmentType: new UntypedFormControl(data.assignmentType || this.assignmentTypes[0].value),
    });
  }

  // add hook
  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.maxScrollAreaHeight = this.getMaxScrollAreaHeight();
    }, 0);
  }
  
  public getMaxScrollAreaHeight(): number {
    const headerHeight = document.querySelector('.workflow-step-modal_header').clientHeight;
    const modalHeaderHeight = document.querySelector('.modal-header').clientHeight;
    return document.body.clientHeight - headerHeight - modalHeaderHeight - 64;
  }

  public save(): void {
    if (this.form.invalid) {
      setTimeout(() => {
        const firstError = document.querySelector('.workflow-step-modal_scroll-area .has-error');
        firstError.scrollIntoView();
      }, 0);

      return;
    }

    this.activeModal.close(this.form.value);
  }
}
