import { type WorkflowStep } from '../models/workflow';
import { WorkflowStepTypeId } from './workflow-step-type-ids';
import { FinishWorkflowModalComponent } from '../workflow-step-modals/finish-workflow-modal/finish-workflow-modal.component';
import { AcknowledgementModalComponent } from '../workflow-step-modals/acknowledgement-modal/acknowledgement-modal.component';
import { UploadFileModalComponent } from '../workflow-step-modals/upload-file-modal/upload-file-modal.component';
import { InstructionalTextModalComponent } from '../workflow-step-modals/instructional-text-modal/instructional-text-modal.component';
import { CustomFormModalComponent } from '../workflow-step-modals/custom-form-modal/custom-form-modal.component';
import { DocusignModalComponent } from '../workflow-step-modals/docusign-modal/docusign-modal.component';
import { ApprovalModalComponent } from '../workflow-step-modals/approval-modal/approval-modal.component';

export const COMPANY_WORKFLOW_STEPS: WorkflowStep[] = [
  {
    stepId: WorkflowStepTypeId.Start,
    text: 'workflows.steps.start',
    category: 'Start',
    drawable: false,
  },
  {
    stepId: WorkflowStepTypeId.Finish,
    icon: 'assets/svg/workflows/finish.svg',
    text: 'workflows.steps.finish',
    fromLinkable: false,
    bgColor: '#28323C',
    modalComponent: FinishWorkflowModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.Approval,
    icon: 'assets/svg/workflows/approval.svg',
    text: 'workflows.steps.approval',
    category: 'Approval',
    fromMaxLinks: 2,
    modalComponent: ApprovalModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.TextAcknowledgment,
    icon: 'assets/svg/workflows/acknowledgement.svg',
    text: 'workflows.steps.acknowledgement',
    modalComponent: AcknowledgementModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.ESign,
    icon: 'assets/svg/workflows/e-sign.svg',
    text: 'workflows.steps.eSign',
    modalComponent: DocusignModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.FileUpload,
    icon: 'assets/svg/workflows/file-upload.svg',
    text: 'workflows.steps.fileUpload',
    modalComponent: UploadFileModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.СustomForm,
    icon: 'assets/svg/workflows/form.svg',
    text: 'workflows.steps.customForm',
    modalComponent: CustomFormModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.InstructionalText,
    icon: 'assets/svg/workflows/instructions.svg',
    text: 'workflows.steps.instructionalText',
    modalComponent: InstructionalTextModalComponent,
  },
];

export const PRODUCT_WORKFLOW_STEPS: WorkflowStep[] = [
  {
    stepId: WorkflowStepTypeId.Start,
    text: 'workflows.steps.start',
    category: 'Start',
    drawable: false,
  },
  {
    stepId: WorkflowStepTypeId.Finish,
    icon: 'assets/svg/workflows/finish.svg',
    text: 'workflows.steps.finish',
    fromLinkable: false,
    bgColor: '#28323C',
    modalComponent: FinishWorkflowModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.Approval,
    icon: 'assets/svg/workflows/approval.svg',
    text: 'workflows.steps.approval',
    category: 'Approval',
    fromMaxLinks: 2,
    modalComponent: ApprovalModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.TextAcknowledgment,
    icon: 'assets/svg/workflows/acknowledgement.svg',
    text: 'workflows.steps.acknowledgement',
    modalComponent: AcknowledgementModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.InstructionalText,
    icon: 'assets/svg/workflows/instructions.svg',
    text: 'workflows.steps.instructionalText',
    modalComponent: InstructionalTextModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.ESign,
    icon: 'assets/svg/workflows/e-sign.svg',
    text: 'workflows.steps.eSign',
    modalComponent: DocusignModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.FileUpload,
    icon: 'assets/svg/workflows/file-upload.svg',
    text: 'workflows.steps.fileUpload',
    modalComponent: UploadFileModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.ProductCustomForm,
    icon: 'assets/svg/workflows/form.svg',
    text: 'workflows.steps.customForm',
    modalComponent: CustomFormModalComponent,
  },
];
