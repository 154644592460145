import { Component, type OnInit, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RichTextEditorComponent } from '../../../core/components/rich-text-editor/rich-text-editor.component';
import { FormErrorMessagesComponent } from '../../../core/components/form-error-messages/form-error-messages.component';
import { NgClass } from '@angular/common';
import { ScrollableAreaComponent } from '../../../core/components/scrollable-area/scrollable-area.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

@Component({
  selector: 'app-instructional-text-modal',
  templateUrl: './instructional-text-modal.component.html',
  styleUrls: ['./instructional-text-modal.component.scss'],
  standalone: true,
  imports: [
    FocusableDirective,
    AssetSrcDirective,
    FormsModule,
    ReactiveFormsModule,
    ScrollableAreaComponent,
    NgClass,
    FormErrorMessagesComponent,
    RichTextEditorComponent,
    TranslateModule,
  ],
})
export class InstructionalTextModalComponent implements OnInit {
  public readonly activeModal = inject(NgbActiveModal);
  @Input() data: any;
  @Input() workflowType: string;

  public form: UntypedFormGroup;
  public maxScrollAreaHeight: number;

  public ngOnInit(): void {
    const data = this.data ? this.data : {};
    if (Object.keys(data).length > 0) {
      const regex = /\[\[\s*(.*?)\s*\]\]/g;
      data.text.html = data.text.html.replace(regex, '<span class="workflow-token">$1</span>');
    }
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(data.name, [Validators.required]),
      description: new UntypedFormControl(data.description, [Validators.required]),
      text: new UntypedFormControl(data.text, [Validators.required]),
    });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.maxScrollAreaHeight = this.getMaxScrollAreaHeight();
    }, 0);
  }

  public getMaxScrollAreaHeight(): number {
    const headerHeight = document.querySelector('.workflow-step-modal_header').clientHeight;
    const modalHeaderHeight = document.querySelector('.modal-header').clientHeight;
    return document.body.clientHeight - headerHeight - modalHeaderHeight - 64;
  }

  public save(): void {
    if (this.form.invalid) {
      setTimeout(() => {
        const firstError = document.querySelector('.workflow-step-modal_scroll-area .has-error');
        firstError.scrollIntoView();
      }, 0);

      return;
    }
    this.activeModal.close(this.form.value);
  }
}
