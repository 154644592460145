import { type WorkflowStep } from '../models/workflow';
import { WorkflowStepTypeId } from './workflow-step-type-ids';
import { SendNotificationModalComponent } from '../workflow-step-modals/send-notification-modal/send-notification-modal.component';
import { SendEmailModalComponent } from '../workflow-step-modals/send-email-modal/send-email-modal.component';
import { DecisionModalComponent } from '../workflow-step-modals/decision-modal/decision-modal.component';
import { ReassignTaskModalComponent } from '../workflow-step-modals/reassign-task-modal/reassign-task-modal.component';

export const COMPANY_WORKFLOW_ACTIONS: WorkflowStep[] = [
  {
    stepId: WorkflowStepTypeId.SendEmail,
    icon: 'assets/svg/workflows/send-email.svg',
    text: 'workflows.actions.sendEmail',
    bgColor: '#27AE60',
    modalComponent: SendEmailModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.Decision,
    icon: 'assets/svg/workflows/action.svg',
    text: 'workflows.actions.decision',
    bgColor: '#27AE60',
    category: 'Decision',
    fromMaxLinks: 2,
    modalComponent: DecisionModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.ReassignTaskAction,
    icon: 'assets/svg/workflows/action.svg',
    text: 'workflows.actions.reassignTask',
    bgColor: '#27AE60',
    modalComponent: ReassignTaskModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.FireWebhook,
    icon: 'assets/svg/workflows/fire-webhook.svg',
    bgColor: '#27AE60',
    text: 'workflows.actions.fireWebhook',
  },
  {
    stepId: WorkflowStepTypeId.SendNotification,
    icon: 'assets/svg/workflows/notification.svg',
    text: 'workflows.actions.sendNotification',
    bgColor: '#27AE60',
    modalComponent: SendNotificationModalComponent,
  },
];

export const PRODUCT_WORKFLOW_ACTIONS: WorkflowStep[] = [
  {
    stepId: WorkflowStepTypeId.SendNotification,
    icon: 'assets/svg/workflows/notification.svg',
    text: 'workflows.actions.sendNotification',
    bgColor: '#27AE60',
    modalComponent: SendNotificationModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.ProductSendEmail,
    icon: 'assets/svg/workflows/send-email.svg',
    text: 'workflows.actions.sendEmail',
    bgColor: '#27AE60',
    modalComponent: SendEmailModalComponent,
  },
  {
    stepId: WorkflowStepTypeId.ProductDecision,
    icon: 'assets/svg/workflows/action.svg',
    text: 'workflows.actions.decision',
    bgColor: '#27AE60',
    category: 'Decision',
    fromMaxLinks: 2,
    modalComponent: DecisionModalComponent,
  },
];
