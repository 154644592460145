export interface WorkflowStep {
  id?: number;
  stepId: number;
  category?: string;
  loc?: string;
  config?: any;
  icon?: string;
  text?: string;
  textWidth?: number;
  bgColor?: string;
  fromLinkable?: boolean;
  drawable?: boolean;
  modalComponent?: any;
  fromMaxLinks?: number;
}

export interface Workflow {
  id?: number;
  name?: string;
  description?: string;
  status?: WorkflowStatus;
  graphConfig?: string;
  imageUrl?: string;
  data?: string;
  thumbnailImage?: string;
  type?: WorkflowType;
}

export enum WorkflowStatus {
  Draft = 'Draft',
  Published = 'Published',
}

export enum WorkflowType {
  Company = 'Company',
  Product = 'Product',
}
