import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { type ServerError } from '@core/models/serverError';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { COMPANY_WORKFLOW_ACTIONS } from '../../constants/workflow-actions';
import { COMPANY_WORKFLOW_STEPS } from '../../constants/workflow-steps';
import { type WorkflowStep } from '../../models/workflow';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';

@Component({
  selector: 'app-validation-errors-modal',
  templateUrl: './validation-errors-modal.component.html',
  styleUrls: ['./validation-errors-modal.component.scss'],
  standalone: true,
  imports: [FocusableDirective, AssetSrcDirective, TranslateModule],
})
export class ValidationErrorsModalComponent {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly translate = inject(TranslateService);
  @Input() errors: ServerError[] = [];
  @Input() data: any;

  public errorMessages: string[] = [];
  private steps: WorkflowStep[] = [];

  public ngOnInit(): void {
    this.steps = [...COMPANY_WORKFLOW_ACTIONS, ...COMPANY_WORKFLOW_STEPS];
    this.errors.forEach((err) => {
      this.getErrorMessage(err);
    });
  }

  public getErrorMessage(error: ServerError): void {
    if (error.errorCode === 'workflow_contains_detached_steps') {
      this.handleDetachedStepsError(error);
      return;
    }

    if (error.errorCode === 'workflow_decisions_connections_are_missing') {
      this.handleDecisionError(error);
      return;
    }

    if (error.errorCode === 'invalid_step_definition' && error.data) {
      this.handleStepDefinitionError(error);
      return;
    }

    const path = `workflows.stepErrors.${error.errorCode}`;
    const translation = this.translate.instant(path);
    if (path !== translation) {
      this.errorMessages.push(translation);
    } else {
      this.errorMessages.push(error.errorMessage);
    }
  }

  private isAction(stepId: number): boolean {
    return COMPANY_WORKFLOW_ACTIONS.findIndex((action) => action.stepId === stepId) !== -1;
  }

  private getStepType(stepId: number): string {
    const result = this.steps.find((step) => step.stepId === stepId);
    if (!result) {
      return 'Unknown';
    }
    return this.translate.instant(result.text);
  }

  private handleDecisionError(error: ServerError): void {
    error.data.invalidSteps.forEach((step) => {
      const path = `workflows.actionErrors.${error.errorCode}`;
      this.errorMessages.push(this.translate.instant(path, { name: step.stepName }));
    });
  }

  private handleDetachedStepsError(error: ServerError): void {
    error.data.invalidSteps.forEach((step) => {
      const errPath = this.isAction(step.stepId) ? 'actionErrors' : 'stepErrors';
      const path = `workflows.${errPath}.${
        step.stepName ? 'workflow_contains_detached_steps' : 'workflow_contains_detached_steps_unnamed'
      }`;
      const type = this.getStepType(step.stepId);
      this.errorMessages.push(this.translate.instant(path, { type, name: step.stepName }));
    });
  }

  public handleStepDefinitionError(error: ServerError): void {
    const invalidCustomFieldNames = [];
    let data;
    try {
      data = JSON.parse(this.data);
    } catch (error) {}

    for (const stepError of Object.values(error.data.errors)) {
      (stepError as any).forEach((err) => {
        if (data && (err.errorCode === 'invalid_field_id' || err.errorCode === 'invalid_target_field_id')) {
          const invalidStep = data.nodeDataArray.find((step) => step.stepId === error.data.stepId);
          if (!invalidStep?.config?.fields) {
            return;
          }
          const invalidField = invalidStep.config.fields.find((field) => field.id === err.data.id);
          if (!invalidField?.title) {
            return;
          }
          invalidCustomFieldNames.push(invalidField.title);
        }
      });
    }

    if (invalidCustomFieldNames.length > 0) {
      this.errorMessages.push(
        this.translate.instant(`workflows.customFieldErrors.hasInactiveFields`, {
          stepName: error.data.stepName,
          fieldNames: invalidCustomFieldNames.reduce((p, c) => `${p}, ${c}`),
        }),
      );
    } else {
      this.errorMessages.push(
        this.translate.instant(`workflows.customFieldErrors.hasUnknownInactiveFields`, {
          stepName: error.data.stepName,
        }),
      );
    }
  }
}
