import { Injectable, inject } from '@angular/core';
import { HttpService } from '../core/services/http.service';
import { type LogicFormField } from '../custom-forms/models/logic-form-field';
import { type DecisionCriteriaState } from './models/decision-criteria';
import { type WorkflowType } from './models/workflow';
import { WorkflowBusinessObject } from '@clover/custom-forms/models/workflow-business-object';

@Injectable({
  providedIn: 'root',
})
export class WorkflowsService {
  private readonly http = inject(HttpService);
  private readonly cache: Record<string, any> = {};
  public logicFields: LogicFormField[] = [];
  public businessObjects: WorkflowBusinessObject[] = [];
  public createdWorkflowType: WorkflowType;

  public async getWorkflow(id: number): Promise<any> {
    return await this.http.get(`/api/companies/my/workflows/${id}`);
  }

  public async updateWorkflow(id: number, data: any): Promise<any> {
    return await this.http.put(`/api/companies/my/workflows/${id}`, data);
  }

  public async createWorkflow(data: any): Promise<any> {
    return await this.http.post(`/api/companies/my/workflows`, data);
  }

  public async validateWorkflow(data: any): Promise<any> {
    return await this.http.post(`/api/companies/my/workflows/validate`, data);
  }

  public async publishWorkflow(workflowId: number, folderId: number): Promise<any> {
    return await this.http.put(`/api/companies/my/workflows/${workflowId}/publish/${folderId}`);
  }

  public async unpublishWorkflow(workflowId: number): Promise<any> {
    return await this.http.put(`/api/companies/my/workflows/${workflowId}/unpublish`);
  }

  public async getWorkflowFolders(): Promise<any> {
    return await this.http.get(`/api/companies/my/workflows/folders`);
  }

  public async createFolder(data: any): Promise<any> {
    return await this.http.post(`/api/companies/my/workflows/folders`, data);
  }

  public async renameFolder(id: number, data: any): Promise<any> {
    return await this.http.put(`/api/companies/my/workflows/folders/${id}`, data);
  }

  public async deleteFolder(id: number): Promise<any> {
    return await this.http.delete(`/api/companies/my/workflows/folders/${id}`);
  }

  public async deleteWorkflow(id: number): Promise<any> {
    return await this.http.delete(`/api/companies/my/workflows/${id}`);
  }

  public async getCompanyFields(): Promise<any> {
    return await this.http.get(`api/companies/fields/schema`);
  }

  //TODO: add object definition view model
  public async getObjectDefinitions(): Promise<any> {
    return await this.http.get(`api/business-objects?paging.limit=100`);
  }

  public async getObjectDefinition(id:string): Promise<any> {
    return await this.http.get(`api/business-objects/${id}`);
  }

  public async getDesicionCriteriaStates(): Promise<Record<string, DecisionCriteriaState[]>> {
    if (this.cache.criteriaStates) {
      return await Promise.resolve(this.cache.criteriaStates);
    }

    return await this.http.get(`/api/workflows/fields/states`).then((res) => {
      this.cache.criteriaStates = res;
      return res;
    });
  }

  public async loadEsignTemplates(): Promise<any> {
    return await this.http.get(`api/esign/templates`);
  }

  public async updateWorkflowAccess(workflowId: number, data: { type: string; teamIds: number[] }): Promise<any> {
    return await this.http.patch(`api/companies/my/workflows/${workflowId}/access`, data);
  }
}
